import React from "react";
import { Link, graphql } from "gatsby";
import Seo from "../../components/Seo";
import Layout from "../../layouts/index";
import Img from "gatsby-image";
import usercentric from "../../images/user-centric-optimized.jpg";
import implimentation from "../../images/implimentation-choice-optimized.jpg";
import mobilesecurity from "../../images/mobile-security-optimized.jpg";
import dataanalytics from "../../images/data-analytics-optimized.jpg";
import ContactUs from "../../components/ContactUs";
import comment from "../../images/comment.png";

const Mobilesolutions = (props) => {
  return (
    <Layout bodyClass="page-services">
      <Seo
        title="Mobile Solutions"
        description="iTelaSoft has been assisting various organisations to build B2C,
                B2B, and B2E mobile solutions and productivity apps. We help
                businesses to steer through their mobile strategy faster,
                smarter and more economically with various services in the
                mobile space."
      />
      <div className="intro intro-banner" id="up">
        <div className="intro-banner-img">
          <Img
            fluid={props.data.bannerImg.childImageSharp.fluid}
            loading="eager"
            fadeIn={true}
            alt="Banner Image"
            className="hero-banner"
          />
        </div>
        <div className="container">
          <div className="row hero-section">
            <div className="col-12 wrap-page-title">
              <h1 className="page-title">Mobile Solutions &amp; Strategy</h1>
              <hr />
            </div>
          </div>
        </div>
      </div>
      <div className="container pb-6 section-2">
        <div className="row">
          <div className="col-12 mt-4 text-center">
            <div className="sub-title-one">Business on the Go</div>
            <hr />
            <div className="discription">
              <p>
                iTelaSoft has been assisting various organisations to build B2C,
                B2B, and B2E mobile solutions and productivity apps. We help
                businesses to steer through their mobile strategy faster,
                smarter and more economically with various services in the
                mobile space. If you are looking to push the envelope and
                focussed on achieving competitive edge through smarter solutions
                we may be the right partner to talk to.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-6 col-sm-12 p-0 d-sm-block img-box">
            <img alt="usercentric" className="img-inno" src={usercentric} />
          </div>
          <div className="col-12 col-md-6">
            <div className="space">
              <h4>User-Centric Design</h4>
              <div className="wrap-border">
                <hr />
              </div>
              <div className="discription">
                <p>
                  User experience is everything. The value of an app does not
                  depend on the number of features it has, but on how good the
                  user experience is. Mobile devices with extremely limited
                  screen real estate, coupled with slow connectivity, can
                  drastically affect the app's ability to provide a delightful
                  user experience. One of our critical elements in crafting a
                  compelling user experience, and consequently a successful app,
                  is close collaboration between the developers, designers and
                  analysis team. In our mobile enablement exercises, we pay
                  special attention to user experience.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 p-0 order-md-first order-last">
            <div className="space">
              <h4>Implementation Choice</h4>
              <div className="wrap-border">
                <hr />
              </div>
              <div className="discription">
                <p>
                  There are many goals in a mobile app project, including time
                  to market, performance, offline capability, device
                  compatibility, and budget. To cater to these diverse needs,
                  different technologies and frameworks do exist. Hybrid
                  Development, Native Development, and Single Codebase Cross
                  Platform Development are some of the top-level options. We
                  work with our customers to match their short-term and
                  long-term business goals with the right technology stack and
                  the development road-map most suitable for their mobile
                  strategy.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-sm-12 p-0 d-sm-block img-box">
            <img alt="implimentation" className="img-inno" src={implimentation} />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 col-sm-12 p-0 d-sm-block img-box">
            <img alt="mobile security" className="img-inno" src={mobilesecurity} />
          </div>
          <div className="col-12 col-md-6 p-0">
            <div className="space">
              <h4>Mobile Security</h4>
              <div className="wrap-border">
                <hr />
              </div>
              <div className="discription">
                <p>
                  Mobile apps feel more intimate to a user than a web
                  application. They tend to store sensitive information both on
                  the device itself and also in the cloud-based information
                  repositories. With ever-increasing concerns of cyber-attacks
                  and user attention to privacy, app owners have a tremendous
                  obligation in implementing and managing the information
                  security of their app. With the latest regulations, such as
                  GDPR, iTelaSoft helps customers to address security concerns
                  from design to deployment.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 p-lg-0 order-md-first order-last">
            <div className="space">
              <h4>Data Analytics</h4>
              <div className="wrap-border">
                <hr />
              </div>
              <div className="discription">
                <p>
                  Smart mobile applications are revolutionising the way people
                  get the work done. Data analytics and machine learning are at
                  the heart of smart applications. With the vast Data Science
                  experience of iTelaSoft, we help apps to become progressively
                  smarter as they accumulate data over time. Not only users, but
                  app owners too, can benefit from the power of analytics by
                  observing and predicting user needs and behaviour.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-sm-12 p-0 d-sm-block img-box">
            <img alt="data analytics" className="img-inno" src={dataanalytics} />
          </div>
        </div>
        <div className="row wrap-contact-us">
          <ContactUs />
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    bannerImg: file(relativePath: { eq: "mobile-startegy-OG-1024x535.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export default Mobilesolutions;
